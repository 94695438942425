<template lang="pug">
.advanced_statistics
  v-container
    v-row(style="height: 92px")
      v-col(cols="auto")
        v-radio-group.justify-center(v-model="teamRadioGroup", row)
          v-radio(
            v-for="(type, index) in typeList",
            :key="index",
            :label="type",
            :value="index"
          )
  .compare(v-if="teamRadioGroup == 0")
    .offensive_mode
      .selector-head
        v-container.h-100.d-flex.justify-space-between.align-center
          v-row
            v-col.text-sm-center.offset-sm-3.offset-md-2(
              cols="6",
              sm="6",
              md="8"
            )
              h5.title 進攻模式
            v-col(cols="6", sm="3", md="2")
              .selector
                select.dark-selector.small(v-model="offModeSelect")
                  option(
                    v-for="(quarter, index) in quarterList",
                    :key="index",
                    :value="index"
                  ) {{ quarter }}

      v-container
        v-row(justify="center")
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ homeTeamName }}
            high-chart-bar(
              :barDataList="homeOffensiveModeDataList",
              :categories="offensiveModeCategoryList"
            )
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ awayTeamName }}
            high-chart-bar(
              :barDataList="awayOffensiveModeDataList",
              :categories="offensiveModeCategoryList"
            )
    .offensive_mode
      .selector-head
        v-container.h-100.d-flex.justify-space-between.align-center
          v-row
            v-col.text-sm-center.offset-sm-3.offset-md-2(
              cols="6",
              sm="6",
              md="8"
            )
              h5.title 快攻來源
            v-col(cols="6", sm="3", md="2")
              .selector
                select.dark-selector.small(v-model="transitionResSelect")
                  option(
                    v-for="(quarter, index) in quarterList",
                    :key="index",
                    :value="index"
                  ) {{ quarter }}

      v-container
        v-row(justify="center")
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ homeTeamName }}
            high-chart-bar(
              :barDataList="homeTransitionDataList",
              :categories="transitionCategoryList"
            )
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ awayTeamName }}
            high-chart-bar(
              :barDataList="awayTransitionDataList",
              :categories="transitionCategoryList"
            )

    .period_trend
      .selector-head
        v-container.h-100.d-flex.justify-space-between.align-center
          v-row
            v-col.text-sm-center.offset-sm-3.offset-md-2(
              cols="6",
              sm="6",
              md="8"
            )
              h5.title 各節趨勢
            v-col(cols="6", sm="3", md="2")
              .selector
                select.dark-selector.small(v-model="periodTrendSelect")
                  option(
                    v-for="(offType, index) in periodTrendSelectList",
                    :key="index",
                    :value="index"
                  ) {{ offType }}
      v-container
        v-row(justify="center")
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ homeTeamName }}
            high-chart-bar(
              :barDataList="homePeriodTrendDataList",
              :categories="periodTrendCategoryList"
            )
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ awayTeamName }}
            high-chart-bar(
              :barDataList="awayPeriodTrendDataList",
              :categories="periodTrendCategoryList"
            )

    .offensive_rate
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 進攻比例
      v-container
        v-row(justify="center")
          v-col.mt-8.mt-md-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ homeTeamName }}
            high-chart-pie(
              :pieDataList="homeOffensiveRateDataList",
              :pppList="homeOffensiveRatePPPList"
            )
          v-col.mt-8.mt-sm-15(cols="12", sm="10", md="6")
            .chart_title.text-center {{ awayTeamName }}
            high-chart-pie(
              :pieDataList="awayOffensiveRateDataList",
              :pppList="awayOffensiveRatePPPList"
            )

    .shot_rate
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 出手比例
      v-container
        v-row(justify="center")
          v-col.mt-8.mt-md-15(cols="12", sm="10", lg="8")
            high-chart-stack-col-bar(
              :title="''",
              :barDataList="shotRateDataList",
              :categories="shotRateCategoryList",
              :barTooltipObject="shotRateMakeObject"
            )
  .individual(v-if="teamRadioGroup == 1 || teamRadioGroup == 2")
    .advanced_statistics
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 進階數據
      v-container.table_container.mt-5.mt-md-8
        v-row.table_row
          v-col.table.table_col
            table
              thead.top_head
                tr
                  sticky-column(colspan="2", :offset="0") 球員
                  th.bdr(colspan="1")
                  th.bdr(colspan="10") 個人數據
                  th.bdr(colspan="4") 上場時團隊效率
              thead
                tr.bottom_head
                  sticky-column.bdl(
                    style="width: 20px; min-width: 20px",
                    :title="'先發'",
                    :offset="0"
                  )
                  sticky-column.bdr2(
                    style="width: 120px; min-width: 120px",
                    title="球員",
                    :offset="45"
                  )
                  th 時間
                  th 正負值
                  th USG%
                  th 進攻效率
                  th USG%（含傳球）
                  th 進攻效率（含傳球）
                  th 失誤率
                  th 防守籃板率
                  th 潛在助攻
                  th 傳球失誤
                  th.bdr 非傳球失誤
                  th 全隊進攻
                  th 對手進攻
                  th 進攻籃板率
                  th 防守籃板率
              tbody
                tr(
                  v-for="(player, index) in advancedStatisticsColumnList",
                  :key="index"
                )
                  sticky-column.bdl(
                    :class="{ text_secondary: true }",
                    style="width: 20px; min-width: 20px",
                    :title="player.is_start ? '◯' : ''",
                    :offset="0"
                  ) 
                  sticky-column.bdr2(
                    style="width: 120px; min-width: 120px",
                    :title="player.description",
                    :offset="45"
                  )
                  td {{ getMinutes(player.total.secs) }}
                  td {{ player.total.plus_minus }}
                  td {{ (player.total.usg * 100).toFixed(1) }}%
                  td {{ player.total.ppp.toFixed(1) }}
                  td {{ (player.total.inc_pass_usg * 100).toFixed(1) }}%
                  td {{ player.total.inc_pass_ppp.toFixed(1) }}
                  td {{ (player.total.tov_rate * 100).toFixed(1) }}%
                  td {{ (player.total.def_reb_rate * 100).toFixed(1) }}%
                  td.hover(
                    @click="dataClick(player, 'potential_ast', '潛在助攻')",
                    :class="[player.total.potential_ast > 0 ? 'link_deep' : 'gray_out_heavy']"
                  ) {{ player.total.potential_ast }}
                  td.hover(
                    @click="dataClick(player, 'pass_tov', '傳球失誤')",
                    :class="[player.total.pass_tov > 0 ? 'link_deep' : 'gray_out_heavy']"
                  ) {{ player.total.pass_tov }}
                  td.bdr.hover(
                    @click="dataClick(player, 'non_pass_tov', '非傳球失誤')",
                    :class="[player.total.non_pass_tov > 0 ? 'link_deep' : 'gray_out_heavy']"
                  ) {{ player.total.non_pass_tov }}

                  td {{ player.total.on_court_ppp.toFixed(1) }}
                  td {{ player.total.on_court_opp_ppp.toFixed(1) }}
                  td {{ (player.total.on_court_off_reb_rate * 100).toFixed(1) }}%
                  td {{ (player.total.on_court_def_reb_rate * 100).toFixed(1) }}%
    .line_up
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 陣容

      v-container.table_container.mt-5.mt-md-8
        v-row.table_row
          v-col.table_col.table.position-relative(cols="12")
            table
              thead
                tr.bottom_head
                  sticky-column.bdl(
                    style="width: 20px; min-width: 20px",
                    :title="'先發'",
                    :offset="0"
                  )
                  sticky-column.bdr2(
                    style="width: 150px; min-width: 150px",
                    :offset="0",
                    title="陣容"
                  )
                  th.bdr 時間
                  th 正負值
                  th 進攻效率
                  th.bdr 對手進攻效率
                  th 防守籃板率
                  th 失誤率

              tbody
                tr(
                  v-for="(roster, index) in rosterColumnsList",
                  :key="index",
                  v-if="index < 6"
                )
                  sticky-column.bdl(
                    :class="{ text_secondary: true }",
                    style="width: 20px; min-width: 20px",
                    :title="roster.is_start ? '◯' : ''",
                    :offset="0"
                  )
                  sticky-column.bdr2(
                    style="width: 150px; min-width: 150px",
                    :offset="0",
                    :title="roster.season_player_names"
                  )

                  td.bdr {{ getMinutes(roster.total.secs) }}
                  td {{ roster.total.plus_minus }}
                  td {{ roster.total.on_court_ppp.toFixed(1) }}
                  td.bdr {{ roster.total.on_court_opp_ppp.toFixed(1) }}
                  td {{ (roster.total.def_reb_rate * 100).toFixed(1) }}%
                  td {{ (roster.total.tov_rate * 100).toFixed(1) }}%
    .game_trend
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 比賽走向
      v-container
        v-row(justify="center")
          v-col.light_blue_border.mt-5.mt-md-15(cols="12", md="8")
            High-chart-trend(
              :title="''",
              :trendDataList="periodTrendLineDataList",
              :categories="periodTrendLineCategoryList"
            )

    .distribution_efficiency
      .selector-head
        v-container.h-100.d-flex.justify-space-between.align-center
          v-row
            v-col.text-sm-center.offset-sm-3.offset-md-2(
              cols="6",
              sm="6",
              md="8"
            )
              h5.title 球權分配與效率
            v-col(cols="6", sm="3", md="2")
              .selector
                select.dark-selector.small(
                  v-model="distributionEfficiencySelect"
                )
                  option(value=0) 進攻效率
                  option(value=1) 進攻效率(含傳球)
                  option(value=2) 進攻效率(上場時)
      v-container
        v-row(justify="center")
          v-col.light_blue_border.mt-5.mt-md-15(cols="12", md="8")
            High-chart-scatter-line(
              :title="''",
              :lineDataList="distributionEfficiencyLineList",
              :scatterDataList="distributionEfficiencyScatterList"
            )

    .offensive_defensive_influence
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 攻守影響
      v-container
        v-row(justify="center")
          v-col.light_blue_border.mt-5.mt-md-15(cols="12", md="8")
            High-chart-scatter-double-line(
              :title="''",
              :line1DataList="offensiveDefensiveInfluenceLine1List",
              :line2DataList="offensiveDefensiveInfluenceLine2List",
              :scatterDataList="offensiveDefensiveInfluenceScatterList"
            )

    .refound_effect
      .selector-head
        v-container.h-100.d-flex.justify-center.align-center
          h5.title.text-center 各項數據影響
      v-container
        v-row.mt-10(justify="center")
          v-radio-group.justify-center(v-model="effectRadioGroup", row)
            v-radio(
              v-for="(btn, index) in selectedRadioBtns",
              :key="index",
              :label="btn.name",
              :value="index"
            )

        v-row(justify="center")
          v-col.light_blue_border.mt-5.mt-md-15(cols="12", md="8")
            high-chart-percent-bar(
              v-if="effectRadioGroup != null",
              :title="selectedRadioBtns[effectRadioGroup].name",
              :barDataList="effectDataList",
              :categories="effectCategoryList",
              :percent="effectPercent"
            )
  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          span.d-block 影片出處：
            a.text_primary(
              href="https://www.youtube.com/c/ssutv/videos",
              target="_blank"
            ) https://www.youtube.com/c/ssutv/videos
          span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            )
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player {{ dialogName }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import HighChartBar from "@/components/common/HighChartBar";
import HighChartPercentBar from "@/components/common/HighChartPercentBar";
import HighChartStackColBar from "@/components/common/HighChartStackColBar";
import HighChartPie from "@/components/common/HighChartPie";
import HighChartTrend from "@/components/common/HighChartTrend";
import HighChartScatterLine from "@/components/common/HighChartScatterLine";
import HighChartScatterDoubleLine from "@/components/common/HighChartScatterDoubleLine";
import StickyColumn from "@/components/common/Table/StickyColumn";
import moment from "moment";
import { getGameAdvanceData } from "@/api/game";
import { getPlayList, getPlayData } from "@/api/play";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";
import { jStat } from "jstat";

export default {
  name: "LeagueGameAdvancedStatistics",
  components: {
    HighChartBar,
    HighChartPercentBar,
    HighChartStackColBar,
    HighChartPie,
    HighChartTrend,
    HighChartScatterLine,
    HighChartScatterDoubleLine,
    StickyColumn,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
  },
  data() {
    return {
      league: this.$route.params.league,
      gameID: this.$route.params.id,
      teamRadioGroup: 0,
      typeList: ["隊伍比較"],
      quarterList: [],
      gameData: {},
      teamGameData: [],

      homeTeamID: 0,
      homeTeamName: 0,
      awayTeamID: 0,
      awayTeamName: 0,
      homeTeamGameData: [],
      awayTeamGameData: [],

      offModeSelect: null,
      offensiveModeCategoryList: [
        "快攻",
        "二波",
        "單打,低位",
        "切傳",
        "擋拆,遞手",
        "定點投籃",
        "空切、無球",
      ],
      homeOffensiveModeDataList: [],
      awayOffensiveModeDataList: [],

      transitionResSelect: null,
      transitionCategoryList: ["失誤後", "搶籃板後", "得分後", "罰球後"],
      homeTransitionDataList: [],
      awayTransitionDataList: [],

      periodTrendSelectList: ["快攻", "二波", "盯⼈進攻", "區域進攻"],
      periodTrendSelect: null,
      periodTrendCategoryList: ["第一節", "第二節", "第三節", "第四節"],
      homePeriodTrendDataList: [],
      awayPeriodTrendDataList: [],

      homeOffensiveRateDataList: [
        {
          type: "pie",
          name: "進攻效率",
          data: [],
        },
      ],
      homeOffensiveRatePPPList: [],
      awayOffensiveRateDataList: [
        {
          type: "pie",
          name: "進攻效率",
          data: [],
        },
      ],
      awayOffensiveRatePPPList: [],

      shotRateDataList: [],
      shotRateCategoryList: [],
      shotRateMakeObject: {},

      advancedStatisticsColumnList: [],
      rosterColumnsList: [],

      teamPlayData: [],
      periodTrendLineDataList: [],
      periodTrendLineCategoryList: [],

      distributionEfficiencySelect: null,
      distributionEfficiencyData: [],
      distributionEfficiencyLineList: [
        [0, 0],
        [100, 1],
      ],
      distributionEfficiencyScatterList: [],

      offensiveDefensiveInfluenceData: [],
      offensiveDefensiveInfluenceLine1List: [
        [0, 0],
        [0, 0],
      ],
      offensiveDefensiveInfluenceLine2List: [
        [0, 0],
        [0, 0],
      ],
      offensiveDefensiveInfluenceScatterList: [],

      selectedRadioBtns: [
        { name: "團隊進攻效率" },
        { name: "對手進攻效率" },
        { name: "團隊防守籃板率" },
        { name: "團隊進攻籃板率" },
        { name: "團隊失誤率" },
      ],
      effectRadioGroup: null,
      effectPlayerDataList: [],
      effectDataList: [],
      effectCategoryList: [],
      effectPercent: false,

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
    teamRadioGroup() {
      this.advancedStatisticsColumnList = [];
      this.rosterColumnsList = [];
      this.distributionEfficiencyData = [];
      this.getPlayerAdvanceData();
      this.getRosterData();
      this.getPeriodTrendLine();
      this.getDistributionEfficiency();
      this.getOffensiveDefensiveInfluence();
      this.getEffectList();
    },
    offModeSelect() {
      this.getOffensiveMode();
    },
    transitionResSelect() {
      this.getTransition();
    },
    periodTrendSelect() {
      this.getPeriodTrend();
    },
    distributionEfficiencySelect() {
      this.getDistributionEfficiency();
    },
    effectRadioGroup() {
      this.getEffectList();
    },
  },
  created() {
    this.getGameAdvanceData();
  },
  methods: {
    getGameAdvanceData() {
      let temp = {
        game_id: this.gameID,
      };
      getGameAdvanceData(temp).then((response) => {
        this.gameData = response.data;
        this.getQuarterList();
        this.teamRadioGroup = 0;
        this.offModeSelect = 0;
        this.transitionResSelect = 0;
        this.periodTrendSelect = 0;
        this.distributionEfficiencySelect = 0;
        this.effectRadioGroup = 0;
        this.homeTeamName = response.data.home_team_name;
        this.awayTeamName = response.data.away_team_name;
        this.teamPlayData = response.data.plays;

        this.typeList.push(response.data.home_team_name);
        this.typeList.push(response.data.away_team_name);
        this.getOffensiveRateData();
        this.getShotRate();
        this.getPlayerAdvanceData();
        this.getRosterData();
        this.getPeriodTrendLine();
        this.getOffensiveDefensiveInfluence();
        this.getEffectList();
      });
    },
    getPlayList(seasonPlayerId, stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        game_id: this.gameID,
        season_player_id: seasonPlayerId,
        group_by: "PLAYER",
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          // console.log("this.playList=", this.playList);
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    getQuarterList() {
      this.quarterList = ["全場"];

      for (let index = 0; index < this.gameData.quarter_count; index++) {
        let quarter = "第" + (index + 1) + "節";
        this.quarterList.push(quarter);
      }

      if (this.gameData.ot > 0) {
        this.quarterList.push("OT");
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    getOffensiveMode() {
      this.homeOffensiveModeDataList = [];
      this.awayOffensiveModeDataList = [];

      this.homeOffensiveModeDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.homeOffensiveModeDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });
      this.awayOffensiveModeDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.awayOffensiveModeDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });

      for (let i in this.offensiveModeCategoryList) {
        switch (Number(i)) {
          case 0:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .transition
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .transition_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .transition
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .transition_pts
            );
            break;
          case 1:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .second_chance
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .second_chance_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .second_chance
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .second_chance_pts
            );
            break;
          case 2:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .iso_ball_handler
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .iso_ball_handler_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .iso_ball_handler
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .iso_ball_handler_pts
            );
            break;
          case 3:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .iso_others
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .iso_others_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .iso_others
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .iso_others_pts
            );
            break;
          case 4:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect].pnr
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect].pnr_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect].pnr
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect].pnr_pts
            );
            break;
          case 5:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect].spot_up
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .spot_up_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect].spot_up
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .spot_up_pts
            );
            break;
          case 6:
            this.homeOffensiveModeDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect].off_ball
            );
            this.homeOffensiveModeDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.offModeSelect]
                .off_ball_pts
            );
            this.awayOffensiveModeDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect].off_ball
            );
            this.awayOffensiveModeDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.offModeSelect]
                .off_ball_pts
            );
            break;
        }
      }
    },
    getTransition() {
      this.homeTransitionDataList = [];
      this.awayTransitionDataList = [];

      this.homeTransitionDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.homeTransitionDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });
      this.awayTransitionDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.awayTransitionDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });

      for (let i in this.transitionCategoryList) {
        switch (Number(i)) {
          case 0:
            this.homeTransitionDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_tov
            );
            this.homeTransitionDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_tov_pts
            );
            this.awayTransitionDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_tov
            );
            this.awayTransitionDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_tov_pts
            );
            break;
          case 1:
            this.homeTransitionDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_reb
            );
            this.homeTransitionDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_reb_pts
            );
            this.awayTransitionDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_reb
            );
            this.awayTransitionDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_reb_pts
            );
            break;
          case 2:
            this.homeTransitionDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_fgm
            );
            this.homeTransitionDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_fgm_pts
            );
            this.awayTransitionDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_fgm
            );
            this.awayTransitionDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_fgm_pts
            );
            break;
          case 3:
            this.homeTransitionDataList[0].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_ft
            );
            this.homeTransitionDataList[1].data.push(
              this.gameData.home_team_quarter_stats[this.transitionResSelect]
                .transition_from_ft_pts
            );
            this.awayTransitionDataList[0].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_ft
            );
            this.awayTransitionDataList[1].data.push(
              this.gameData.away_team_quarter_stats[this.transitionResSelect]
                .transition_from_ft_pts
            );
            break;
        }
      }
    },
    getPeriodTrend() {
      this.homePeriodTrendDataList = [];
      this.awayPeriodTrendDataList = [];

      this.homePeriodTrendDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.homePeriodTrendDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });
      this.homePeriodTrendDataList.push({
        name: "失誤",
        data: [],
        color: "#FFBB50",
      });

      this.awayPeriodTrendDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      this.awayPeriodTrendDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });
      this.awayPeriodTrendDataList.push({
        name: "失誤",
        data: [],
        color: "#FFBB50",
      });

      if (this.periodTrendSelect == 0) {
        for (let index in this.gameData.home_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.home_team_quarter_stats[index];
            this.homePeriodTrendDataList[0].data.push(temp.transition_offense);
            this.homePeriodTrendDataList[1].data.push(
              temp.transition_offense_pts
            );
            this.homePeriodTrendDataList[2].data.push(
              temp.transition_offense_to
            );
          }
        }
        for (let index in this.gameData.away_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.away_team_quarter_stats[index];
            this.awayPeriodTrendDataList[0].data.push(
              temp.second_chance_offense
            );
            this.awayPeriodTrendDataList[1].data.push(
              temp.second_chance_offense_pts
            );
            this.awayPeriodTrendDataList[2].data.push(
              temp.second_chance_offense_to
            );
          }
        }
      } else if (this.periodTrendSelect == 1) {
        for (let index in this.gameData.home_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.home_team_quarter_stats[index];
            this.homePeriodTrendDataList[0].data.push(
              temp.second_chance_offense
            );
            this.homePeriodTrendDataList[1].data.push(
              temp.second_chance_offense_pts
            );
            this.homePeriodTrendDataList[2].data.push(
              temp.second_chance_offense_to
            );
          }
        }
        for (let index in this.gameData.away_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.away_team_quarter_stats[index];
            this.awayPeriodTrendDataList[0].data.push(
              temp.second_chance_offense
            );
            this.awayPeriodTrendDataList[1].data.push(temp.second_chance_pts);
            this.awayPeriodTrendDataList[2].data.push(temp.second_chance_to);
          }
        }
      } else if (this.periodTrendSelect == 2) {
        for (let index in this.gameData.home_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.home_team_quarter_stats[index];
            this.homePeriodTrendDataList[0].data.push(temp.man_offense);
            this.homePeriodTrendDataList[1].data.push(temp.man_offense_pts);
            this.homePeriodTrendDataList[2].data.push(temp.man_offense_to);
          }
        }
        for (let index in this.gameData.away_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.away_team_quarter_stats[index];
            this.awayPeriodTrendDataList[0].data.push(temp.man_offense);
            this.awayPeriodTrendDataList[1].data.push(temp.man_offense_pts);
            this.awayPeriodTrendDataList[2].data.push(temp.man_offense_to);
          }
        }
      } else if (this.periodTrendSelect == 3) {
        for (let index in this.gameData.home_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.home_team_quarter_stats[index];
            this.homePeriodTrendDataList[0].data.push(temp.zone_offense);
            this.homePeriodTrendDataList[1].data.push(temp.zone_offense_pts);
            this.homePeriodTrendDataList[2].data.push(temp.zone_offense_to);
          }
        }
        for (let index in this.gameData.away_team_quarter_stats) {
          if (Number(index) > 0) {
            let temp = this.gameData.away_team_quarter_stats[index];
            this.awayPeriodTrendDataList[0].data.push(temp.zone_offense);
            this.awayPeriodTrendDataList[1].data.push(temp.zone_offense_pts);
            this.awayPeriodTrendDataList[2].data.push(temp.zone_offense_to);
          }
        }
      }
    },
    getOffensiveRateData() {
      this.homeOffensiveRateDataList[0].data = [];
      this.awayOffensiveRateDataList[0].data = [];
      this.homeOffensiveRatePPPList = [];
      this.awayOffensiveRatePPPList = [];

      if (
        this.gameData.home_team_quarter_stats[0].full_court_offense_rate != 0
      ) {
        this.homeOffensiveRateDataList[0].data.push({
          name: "全場壓迫",
          y: this.gameData.home_team_quarter_stats[0].full_court_offense_rate,
          color: "#487AA4",
        });
        this.homeOffensiveRatePPPList.push(
          this.gameData.home_team_quarter_stats[0].full_court_offense_ppp.toFixed(
            1
          )
        );
      }
      if (this.gameData.home_team_quarter_stats[0].zone_offense_rate != 0) {
        this.homeOffensiveRateDataList[0].data.push({
          name: "半場區域",
          y: this.gameData.home_team_quarter_stats[0].zone_offense_rate,
          color: "#94B7D4",
        });
        this.homeOffensiveRatePPPList.push(
          this.gameData.home_team_quarter_stats[0].zone_offense_ppp.toFixed(1)
        );
      }
      if (this.gameData.home_team_quarter_stats[0].man_offense_rate != 0) {
        this.homeOffensiveRateDataList[0].data.push({
          name: "半場盯人",
          y: this.gameData.home_team_quarter_stats[0].man_offense_rate,
          color: "#CCDDEB",
        });
        this.homeOffensiveRatePPPList.push(
          this.gameData.home_team_quarter_stats[0].man_offense_ppp.toFixed(1)
        );
      }
      if (
        this.gameData.home_team_quarter_stats[0].transition_offense_rate != 0
      ) {
        this.homeOffensiveRateDataList[0].data.push({
          name: "快攻",
          y: this.gameData.home_team_quarter_stats[0].transition_offense_rate,
          color: "#BEDDF6",
        });
        this.homeOffensiveRatePPPList.push(
          this.gameData.home_team_quarter_stats[0].transition_offense_ppp.toFixed(
            1
          )
        );
      }
      if (
        this.gameData.home_team_quarter_stats[0].second_chance_offense_rate != 0
      ) {
        this.homeOffensiveRateDataList[0].data.push({
          name: "二波",
          y: this.gameData.home_team_quarter_stats[0]
            .second_chance_offense_rate,
          color: "#EEF7FF",
        });
        this.homeOffensiveRatePPPList.push(
          this.gameData.home_team_quarter_stats[0].second_chance_offense_ppp.toFixed(
            1
          )
        );
      }

      if (
        this.gameData.away_team_quarter_stats[0].full_court_offense_rate != 0
      ) {
        this.awayOffensiveRateDataList[0].data.push({
          name: "全場壓迫",
          y: this.gameData.away_team_quarter_stats[0].full_court_offense_rate,
          color: "#487AA4",
        });
        this.awayOffensiveRatePPPList.push(
          this.gameData.away_team_quarter_stats[0].full_court_offense_ppp.toFixed(
            1
          )
        );
      }
      if (this.gameData.away_team_quarter_stats[0].zone_offense_rate != 0) {
        this.awayOffensiveRateDataList[0].data.push({
          name: "半場區域",
          y: this.gameData.away_team_quarter_stats[0].zone_offense_rate,
          color: "#94B7D4",
        });
        this.awayOffensiveRatePPPList.push(
          this.gameData.away_team_quarter_stats[0].zone_offense_ppp.toFixed(1)
        );
      }
      if (this.gameData.away_team_quarter_stats[0].man_offense_rate != 0) {
        this.awayOffensiveRateDataList[0].data.push({
          name: "半場盯人",
          y: this.gameData.away_team_quarter_stats[0].man_offense_rate,
          color: "#CCDDEB",
        });
        this.awayOffensiveRatePPPList.push(
          this.gameData.away_team_quarter_stats[0].man_offense_ppp.toFixed(1)
        );
      }
      if (
        this.gameData.away_team_quarter_stats[0].transition_offense_rate != 0
      ) {
        this.awayOffensiveRateDataList[0].data.push({
          name: "快攻",
          y: this.gameData.away_team_quarter_stats[0].transition_offense_rate,
          color: "#BEDDF6",
        });
        this.awayOffensiveRatePPPList.push(
          this.gameData.away_team_quarter_stats[0].transition_offense_ppp.toFixed(
            1
          )
        );
      }
      if (
        this.gameData.away_team_quarter_stats[0].second_chance_offense_rate != 0
      ) {
        this.awayOffensiveRateDataList[0].data.push({
          name: "二波",
          y: this.gameData.away_team_quarter_stats[0]
            .second_chance_offense_rate,
          color: "#EEF7FF",
        });
        this.awayOffensiveRatePPPList.push(
          this.gameData.away_team_quarter_stats[0].second_chance_offense_ppp.toFixed(
            1
          )
        );
      }
    },
    getShotRate() {
      this.shotRateDataList = [];
      this.shotRateCategoryList = [];
      this.shotRateCategoryList.push(this.homeTeamName);
      this.shotRateCategoryList.push(this.awayTeamName);

      this.shotRateDataList.push({
        name: "禁區",
        data: [],
        color: "#328BD7",
      });
      this.shotRateDataList.push({
        name: "外線空擋",
        data: [],
        color: "#38C077",
      });
      this.shotRateDataList.push({
        name: "外線干擾",
        data: [],
        color: "#E98A3E",
      });
      this.shotRateDataList.push({
        name: "失誤",
        data: [],
        color: "#E2252F",
      });
      this.shotRateDataList.push({
        name: "上罰球線",
        data: [],
        color: "#E2C425",
      });

      this.shotRateDataList[0].data.push({
        y: this.gameData.home_team_quarter_stats[0].paint,
        dataLabels: {
          format:
            this.gameData.home_team_quarter_stats[0].paint != 0
              ? "{point.y}次"
              : "",
        },
      });
      this.shotRateDataList[0].data.push({
        y: this.gameData.away_team_quarter_stats[0].paint,
        dataLabels: {
          format:
            this.gameData.away_team_quarter_stats[0].paint != 0
              ? "{point.y}次"
              : "",
        },
      });

      this.shotRateMakeObject.home_paint =
        this.gameData.home_team_quarter_stats[0].paint_fgm;
      this.shotRateMakeObject.away_paint =
        this.gameData.away_team_quarter_stats[0].paint_fgm;

      this.shotRateDataList[1].data.push({
        y: this.gameData.home_team_quarter_stats[0].open,
        dataLabels: {
          format:
            this.gameData.home_team_quarter_stats[0].open != 0
              ? "{point.y}次"
              : "",
        },
      });
      this.shotRateDataList[1].data.push({
        y: this.gameData.away_team_quarter_stats[0].open,
        dataLabels: {
          format:
            this.gameData.away_team_quarter_stats[0].open != 0
              ? "{point.y}次"
              : "",
        },
      });

      this.shotRateMakeObject.home_open =
        this.gameData.home_team_quarter_stats[0].open_fgm;
      this.shotRateMakeObject.away_open =
        this.gameData.away_team_quarter_stats[0].open_fgm;

      this.shotRateDataList[2].data.push({
        y: this.gameData.home_team_quarter_stats[0].contested,
        dataLabels: {
          format:
            this.gameData.home_team_quarter_stats[0].contested != 0
              ? "{point.y}次"
              : "",
        },
      });
      this.shotRateDataList[2].data.push({
        y: this.gameData.away_team_quarter_stats[0].contested,
        dataLabels: {
          format:
            this.gameData.away_team_quarter_stats[0].contested != 0
              ? "{point.y}次"
              : "",
        },
      });

      this.shotRateMakeObject.home_contested =
        this.gameData.home_team_quarter_stats[0].contested_fgm;
      this.shotRateMakeObject.away_contested =
        this.gameData.away_team_quarter_stats[0].contested_fgm;

      this.shotRateDataList[3].data.push({
        y: this.gameData.home_team_quarter_stats[0].tov,

        dataLabels: {
          format:
            this.gameData.home_team_quarter_stats[0].tov != 0
              ? "{point.y}次"
              : "",
        },
      });

      this.shotRateDataList[3].data.push({
        y: this.gameData.away_team_quarter_stats[0].tov,
        dataLabels: {
          format:
            this.gameData.away_team_quarter_stats[0].tov != 0
              ? "{point.y}次"
              : "",
        },
      });

      this.shotRateDataList[4].data.push({
        y: this.gameData.home_team_quarter_stats[0].draw_shooting_foul,
        dataLabels: {
          format:
            this.gameData.home_team_quarter_stats[0].draw_shooting_foul != 0
              ? "{point.y}次"
              : "",
        },
      });
      this.shotRateDataList[4].data.push({
        y: this.gameData.away_team_quarter_stats[0].draw_shooting_foul,
        dataLabels: {
          format:
            this.gameData.away_team_quarter_stats[0].draw_shooting_foul != 0
              ? "{point.y}次"
              : "",
        },
      });
    },
    getPlayerAdvanceData() {
      if (this.teamRadioGroup == 1) {
        this.advancedStatisticsColumnList = this.gameData.home_player_stats;
      } else if (this.teamRadioGroup == 2) {
        this.advancedStatisticsColumnList = this.gameData.away_player_stats;
      }
    },
    getRosterData() {
      if (this.teamRadioGroup == 1) {
        this.rosterColumnsList = this.gameData.home_roster_stats;
      } else if (this.teamRadioGroup == 2) {
        this.rosterColumnsList = this.gameData.away_roster_stats;
      }
    },
    getPeriodTrendLine() {
      this.periodTrendLineDataList = [];
      this.periodTrendLineCategoryList = [];

      let quarterArray = [];
      for (let index in this.gameData.plays) {
        let temp = this.gameData.plays[index];

        if (!quarterArray.includes(temp.quarter)) {
          quarterArray.push(temp.quarter);

          if (temp.quarter <= this.gameData.quarter_count) {
            let quarter = "第" + temp.quarter + "節";
            this.periodTrendLineCategoryList.push(quarter);
          } else {
            let quarter = "OT" + (temp.quarter - this.gameData.quarter_count);
            this.periodTrendLineCategoryList.push(quarter);
          }
        }

        if (this.teamRadioGroup == 1) {
          this.periodTrendLineDataList.push(temp.home_pts_diff);
        } else if (this.teamRadioGroup == 2) {
          this.periodTrendLineDataList.push(temp.away_pts_diff);
        }
      }
    },
    getDistributionEfficiency() {
      this.distributionEfficiencyScatterList = [];
      if (this.teamRadioGroup == 1) {
        this.distributionEfficiencyData = this.gameData.home_player_stats;
      } else if (this.teamRadioGroup == 2) {
        this.distributionEfficiencyData = this.gameData.away_player_stats;
      }

      for (let index in this.distributionEfficiencyData) {
        let temp = this.distributionEfficiencyData[index];
        if (this.distributionEfficiencySelect == 0 && temp.total.usg != 0) {
          let data = {
            name: temp.season_player__name,
            x: (temp.total.usg * 100).toFixed(1),
            y: Number(temp.total.ppp.toFixed(1)),
          };
          this.distributionEfficiencyScatterList.push(data);
        }

        if (this.distributionEfficiencySelect == 1 && temp.total.usg != 0) {
          let data = {
            name: temp.season_player__name,
            x: (temp.total.usg * 100).toFixed(1),
            y: Number(temp.total.inc_pass_ppp.toFixed(1)),
          };
          this.distributionEfficiencyScatterList.push(data);
        }

        if (this.distributionEfficiencySelect == 2 && temp.total.usg != 0) {
          let data = {
            name: temp.season_player__name,
            x: (temp.total.usg * 100).toFixed(1),
            y: Number(temp.total.on_court_ppp.toFixed(1)),
          };
          this.distributionEfficiencyScatterList.push(data);
        }
      }
      if (this.distributionEfficiencyScatterList.length > 0)
        this.distributionEfficiencyLineList = this.regression(
          this.distributionEfficiencyScatterList
        );
    },
    regression(data) {
      // Example data: [{x: 0, y: 0}]
      const xList = data.map((point) => Number(point.x));
      const yList = data.map((point) => point.y);
      const constantXList = xList.map((x) => [1, x]);
      const model = jStat.models.ols(yList, constantXList); // y = α + β * x
      const a = model.coef[0]; // y-intercept
      const b = model.coef[1]; // Slope
      //Set up a line
      let y1, y2, x1, x2;
      x1 = jStat.min(xList);
      x2 = jStat.max(xList);
      y1 = a + b * x1;
      y2 = a + b * x2;
      return [
        [x1, y1],
        [x2, y2],
      ];
    },
    getOffensiveDefensiveInfluence() {
      this.offensiveDefensiveInfluenceScatterList = [];

      if (this.teamRadioGroup == 1) {
        this.offensiveDefensiveInfluenceData = this.gameData.home_player_stats;
      } else if (this.teamRadioGroup == 2) {
        this.offensiveDefensiveInfluenceData = this.gameData.away_player_stats;
      }

      let XMax = 0;
      let YMax = 0;

      for (let index in this.offensiveDefensiveInfluenceData) {
        let temp = this.offensiveDefensiveInfluenceData[index];

        if (
          Math.abs(temp.total.on_court_pts - temp.total.off_court_pts) > XMax
        ) {
          XMax = Math.abs(temp.total.on_court_pts - temp.total.off_court_pts);
        }

        if (
          Math.abs(temp.total.off_court_opp_pts - temp.total.on_court_opp_pts) >
          YMax
        ) {
          YMax = Math.abs(
            temp.total.off_court_opp_pts - temp.total.on_court_opp_pts
          );
        }

        let data = {
          name: temp.season_player__name,
          x: temp.total.on_court_pts - temp.total.off_court_pts,
          y: temp.total.off_court_opp_pts - temp.total.on_court_opp_pts,
        };

        this.offensiveDefensiveInfluenceScatterList.push(data);
      }

      this.offensiveDefensiveInfluenceLine1List = [];
      this.offensiveDefensiveInfluenceLine1List.push([0 - XMax - 5, 0]);
      this.offensiveDefensiveInfluenceLine1List.push([XMax + 5, 0]);

      this.offensiveDefensiveInfluenceLine2List = [];
      this.offensiveDefensiveInfluenceLine2List.push([0, 0 - YMax - 5]);
      this.offensiveDefensiveInfluenceLine2List.push([0, YMax + 5]);
    },
    getEffectList() {
      this.effectDataList = [];
      this.effectCategoryList = [];
      this.effectDataList.push({
        data: [],
        color: "#487AA4",
        name: this.selectedRadioBtns[this.effectRadioGroup].name,
      });

      if (this.teamRadioGroup == 1) {
        this.effectPlayerDataList = this.gameData.home_player_stats;
      } else if (this.teamRadioGroup == 2) {
        this.effectPlayerDataList = this.gameData.away_player_stats;
      }

      for (let index in this.effectPlayerDataList) {
        let temp = this.effectPlayerDataList[index];

        this.effectCategoryList.push(temp.season_player__name);
        if (this.effectRadioGroup == 0) {
          this.effectPercent = false;
          this.effectDataList[0].data.push(
            Number(temp.total.eff_ppp.toFixed(1))
          );
        } else if (this.effectRadioGroup == 1) {
          this.effectPercent = false;
          this.effectDataList[0].data.push(
            Number(temp.total.eff_opp_ppp.toFixed(1))
          );
        } else if (this.effectRadioGroup == 2) {
          this.effectPercent = true;
          this.effectDataList[0].data.push(
            Number((temp.total.eff_def_reb_rate * 100).toFixed(1))
          );
        } else if (this.effectRadioGroup == 3) {
          this.effectPercent = true;
          this.effectDataList[0].data.push(
            Number((temp.total.eff_off_reb_rate * 100).toFixed(1))
          );
        } else if (this.effectRadioGroup == 4) {
          this.effectPercent = true;
          this.effectDataList[0].data.push(
            Number((temp.total.eff_tov_rate * 100).toFixed(1))
          );
        }
      }
    },
    getVideoPlayData(playerID, playType) {
      this.showLoadingDialog("載入中...");
      let temp = {
        game_id: this.gameID,
        player_id: playerID,
        play_type: playType,
      };
      getPlayData(temp)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    dataClick(player, stat, event) {
      this.dialogNameShowType = true;
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = player.season_player__name;
      this.dialogTeam = player.season_team__name;
      this.dialogEvent = event;
      this.getPlayList(player.season_player__id, stat);
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].video,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      var startSeconds =
        play.result == "DRAW FOUL"
          ? moment.duration(play.video_end_at).asSeconds()
          : moment.duration(play.video_start_at).asSeconds();
      this.scrollToTop();
      this.YTPlayer.loadVideoById({
        videoId: play.video,
        startSeconds: startSeconds - play.video_duration,
        endSeconds: moment.duration(play.video_start_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/game/advanced_statistics";
</style>

import request from "@/utils/request";

export function getPlayData(params) {
  return request({
    url: "/play_data/",
    method: "get",
    params,
  });
}

export function getPlayList(params, data) {
  return request({
    url: "/play_list/",
    method: "post",
    params: params,
    data,
  });
}
